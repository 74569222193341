// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "@ngxpert/hot-toast/src/styles/styles.scss";
// Plus imports for other components in your app.
//@use "@fontsource/lato/400.css";
//@use "@fontsource/lato/700.css";
@use "root";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

.mat-mdc-tooltip,
.mdc-tooltip__surface {
  --mdc-plain-tooltip-supporting-text-line-height: normal;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
}

* {
  box-sizing: border-box;
}

html,
body,
app-root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: var(--pws-font);
}

body {
  background-color: var(--pws-body-bg);
  color: var(--pws-body-color);
}

.as-split-gutter {
  background-color: var(--pws-bg-200) !important;
}

s {
  flex-grow: 1;
}

.mat-mdc-dialog-container {
  display: flex !important;

  > * {
    flex-grow: 1;
  }
}

.d-flex {
  display: flex;
}

svg-icon > svg {
  fill: currentColor;
}

.w-100 {
  width: 100%;
}

.logo-title[fill] {
  fill: var(--pws-body-color);
}

.logo-subtitle[fill] {
  fill: var(--pws-text-muted);
}

.logo-subtitle[stroke] {
  stroke: var(--pws-text-muted);
}

.logo-icon[fill] {
  fill: var(--pws-color-primary);
}

.portugol-renderer-dialog .mat-mdc-dialog-content {
  max-height: unset;
}
