@use "theme-dark";
@use "theme-light";
@use "utils";

@use "sass:color";
@use "sass:map";

$theme-modes: (
  "light": theme-light.$theme,
  "dark": theme-dark.$theme
);

@each $key, $theme in $theme-modes {
  [data-theme="#{$key}"] {
    @each $key, $value in map.get($theme, "$theme-colors") {
      --pws-color-#{$key}: #{$value};
      --pws-color-#{$key}-rgb: #{utils.to-rgb($value)};
    }

    @each $key, $value in map.get($theme, "$theme-text-colors") {
      --pws-text-#{$key}: #{$value};
      --pws-text-#{$key}-rgb: #{utils.to-rgb($value)};
    }

    @each $key, $value in map.get($theme, "$theme-background-colors") {
      --pws-bg-#{$key}: #{$value};
      --pws-bg-#{$key}-rgb: #{utils.to-rgb($value)};
    }

    ///////////
    // Fonte //
    ///////////

    --pws-font: #{theme-dark.$font-family};

    ////////////
    // Página //
    ////////////

    --pws-body-bg: #{map.get($theme, "$body-bg")};
    --pws-body-bg-rgb: #{utils.to-rgb(map.get($theme, "$body-bg"))};
    --pws-body-color: #{map.get($theme, "$body-color")};
    --pws-body-color-rgb: #{utils.to-rgb(map.get($theme, "$body-color"))};
    --pws-body-color-inverted: #{color.invert(map.get($theme, "$body-color"))};
    --pws-body-color-inverted-rgb: #{utils.to-rgb(color.invert(map.get($theme, "$body-color")))};

    --pws-text-muted: var(--pws-text-300);
    --pws-text-muted-rgb: var(--pws-text-300-rgb);

    --pws-border-color: #{map.get($theme, "$border-color")};

    ////////////////////
    // Componente Aba //
    ////////////////////

    --pws-tab-bg: var(--pws-bg-300);
    --pws-tab-bg-rgb: var(--pws-bg-300-rgb);

    --pws-tab-bg-hover: var(--pws-bg-100);
    --pws-tab-bg-hover-rgb: var(--pws-bg-100-rgb);

    --pws-tab-bg-active: var(--pws-bg-200);
    --pws-tab-bg-active-rgb: var(--pws-bg-200-rgb);

    --pws-tab-color: var(--pws-text-300);
    --pws-tab-color-rgb: var(--pws-text-300-rgb);

    --pws-tab-color-hover: var(--pws-text-100);
    --pws-tab-color-hover-rgb: var(--pws-text-100-rgb);

    --pws-tab-color-active: var(--pws-text-200);
    --pws-tab-color-active-rgb: var(--pws-text-200-rgb);

    --pws-tab-body-bg: var(--pws-bg-200);
    --pws-tab-body-bg-rgb: var(--pws-bg-200-rgb);

    --pws-tab-body-color: var(--pws-text-200);
    --pws-tab-body-color-rgb: var(--pws-text-200-rgb);

    /////////////
    // Classes //
    /////////////

    @each $key, $value in map.get($theme, "$theme-colors") {
      .text-#{$key} {
        color: var(--pws-color-#{$key}) !important;
      }

      .bg-#{$key} {
        background-color: var(--pws-color-#{$key}) !important;
      }
    }
  }
}
